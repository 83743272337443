const menu = $('.js-menu'),
      body = $('body');

$(document).on('click', '.js-openMenu', function() {
  let _this = $(this);

  menu.toggleClass('is_open');
  _this.toggleClass('is_active');
  body.toggleClass('overflow_hidden is_open_menu');
});
