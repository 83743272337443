$(document).on('change', 'input[type="checkbox"]', (e) => {
  const $target = $(e.target)
  const $checkboxWrap = $target.parents('.js-checkbox-level-wrap')
  const $parentCheckbox = $checkboxWrap.find('.js-checkbox-level')
  const $checkboxList = $checkboxWrap.find('.js-checkbox-level-list')
  const $levelCheckboxes = $checkboxList.find('input[type="checkbox"]')

  if ($target[0] === $parentCheckbox[0]) {
    $levelCheckboxes.prop('checked', $target.is(':checked'))
  } else {
    const checkedCheckboxes = $levelCheckboxes.filter((i, item) => {
      return $(item).is(':checked')
    })

    $parentCheckbox.prop({
      indeterminate: checkedCheckboxes.length !== 0 && checkedCheckboxes.length < $levelCheckboxes.length,
      checked: checkedCheckboxes.length === $levelCheckboxes.length,
    })
  }
})
