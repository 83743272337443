window.selectInit = function () {
  $('.js-select2Single').each(function () {
    $(this).select2({
      theme: 'default form-select2',
      minimumResultsForSearch: 100000,
      placeholder: $(this).data('placeholder'),
      width: '100%',
      language: {
        noResults: function () {
          return $(this).data('noresult-title');
        }
      },
    });
  });
}

$(window).on('load', function () {
  selectInit();
});
